<template>
  <div class="application-detail" :class="{skeleton: !state.application.loaded}">
    <div class="header">
      <div class="top">
        <div class="wrapper">
          <b class="title">{{ modalParams.application.archiveTitle }}</b>
          <div class="side">
            <div>
              <span class="text">{{ state.application.info.memberName }} / {{ state.application.info.memberId }}</span>
              <span v-if="state.application.info.finished" class="text">최초 제출 일시: {{ state.application.info.submitDate }}</span>
              <span class="ico" title="신청서 수정하기" @click="edit()">
              <i class="fa fa-pencil"></i>
            </span>
              <span class="ico" title="신청서 삭제하기" @click="remove()">
              <i class="fa fa-trash"></i>
            </span>
              <span v-if="!state.application.info.canceled" class="ico mr-0" title="신청서 취소하기" @click="cancel()">
              <i class="fa fa-ban"></i>
            </span>
              <span v-else class="ico mr-0" title="신청서 복원하기" @click="restore()">
              <i class="fa fa-undo"></i>
            </span>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div>
          <select class="form-control" v-model="state.application.status">
            <option value="unproven">접수 완료</option>
            <option value="judge">심사 중</option>
            <option value="pass">통과</option>
            <option value="denied">보완</option>
            <option value="fail">반려</option>
            <option value="done">처리 완료</option>
          </select>
          <a class="btn btn-default history" @click="history">신청서 이력</a>
        </div>
        <div>
          <div class="managers" v-for="(selectedManager, idx) in state.application.assignedManagers" :key="idx">
            <span :class="getManagerClass(selectedManager.memberName)">{{ selectedManager.memberName }}</span>
          </div>
          <div class="dropdown filter">
            <button class="btn btn-default dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="!state.application.loaded">담당자</button>
            <div class="dropdown-menu menu" aria-labelledby="dropdownMenuButton" @click.stop>
              <div v-for="(manager, idx) in state.application.managers" :key="idx">
                <div class="item dropdown-item" @click="toggleManagerSelection(manager)">
                  <i class="fa fa-check" :class="{active: isSelected(manager.memberSeq)}"></i>
                  <span>{{ manager.memberName }}</span>
                </div>
              </div>
            </div>
          </div>
          <a :href="`/download/admin/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}/files`" target="_blank" rel="noopener noreferrer" class="btn btn-default">
            <i class="fa fa-download"></i>
            <span> 첨부파일 전체 다운로드</span>
          </a>
        </div>
      </div>
    </div>
    <div class="alert mt-4 p-3 text-center" v-if="state.application.info.canceled">이 신청서는 취소되었습니다.</div>
    <div class="content" v-for="(s, idx1) in state.application.info.steps" :key="idx1">
      <b class="title">{{ s.title }}</b>
      <table class="table table-bordered">
        <tr v-for="(i, idx2) in s.items" :key="idx2">
          <th v-if="i.type === 'checklist'">
            <div :title="i.content">{{ i.content }}</div>
          </th>
          <th v-else>
            <div :title="i.title">{{ i.title }}</div>
            <div class="desc">{{ i.description }}</div>
            <div v-for="(f, idx4) in computedItemFiles" :key="idx4">
              <template v-if="f.itemId === i.id">
                <span v-if="modalParams.application.id === f.applicationId" class="remove" title="추가 샘플 파일 삭제" @click="removeFile(f.id, idx1, idx2)">
                 <i class="fa fa-trash"></i>
               </span>
                <a v-if="f.id && f.extension && ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(f.extension.toLowerCase())"
                   :href="`/preview/archives/sample-file/${f.id}`" target="_blank" title="클릭하여 미리보기">
                  <span :class="{green: f.applicationId, blue: !f.applicationId}">{{ f.originName }}</span>
                </a>
                <span :class="{green: f.applicationId, blue: !f.applicationId}">{{ f.originName }}</span>
              </template>
            </div>
            <label v-if="i.type === 'file'" class="btn btn-default" @click.stop="clearInput($event)">
              <i class="fa fa-upload"></i>
              <input type="file" :accept="$definitions.limits.fileExtensions.allStr" class="hide" @change="setFile($event, i.id, modalParams.application.id)" title="클릭하면 샘플 파일 업로드"/>
              <span>샘플 추가</span>
            </label>
          </th>
          <td>
            <template v-if="i.answers.length">
              <div v-for="(a, idx3) in i.answers" :key="idx3" :class="{file: i.type === 'file'}">
                <div class="vertical-layout">
                  <template v-if="!['file', 'checklist', 'agreement', 'checkbox', 'select', 'editor', 'currency'].includes(i.type)">
                    <span :class="{empty: !a.value || (a.value && !a.value.trim())}">{{ (a.value && a.value.trim()) ? a.value.trim() : "(N/A)" }}</span>
                  </template>
                  <template v-else-if="['currency'].includes(i.type)">
                    <span :class="{empty: !a.value || (a.value && !a.value.trim())}">{{ formatCurrency(a.value) }}</span>
                  </template>
                  <template v-else-if="['checklist', 'agreement', 'checkbox', 'select'].includes(i.type)">
                    <span v-if="i.options[i.options.findIndex(o => o.id.toString() === a.value)]">{{ i.options[i.options.findIndex(o => o.id.toString() === a.value)].title }}</span>
                  </template>
                  <template v-else-if="['editor'].includes(i.type)">
                    <span class="editor">
                      <TinyEditor :value.sync="a.value" :height="150"/>
                    </span>
                  </template>
                  <template v-else>
                    <span class="file-name">{{ a.fileOriginName && a.fileOriginName.trim() }}</span>
                  </template>
                  <template v-if="state.application.status !== 'pass' && a.reason">
                    <div>
                      <span class="remove" title="보완 사유 삭제하기" @click="removeReject(a.id)">
                        <i class="fa fa-trash"></i>
                      </span>
                      <span class="reason">{{ a.reason }}</span>
                    </div>
                  </template>
                </div>
                <div class="actions">
                  <a v-if="a.extension && ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf'].includes(a.extension.toLowerCase())"
                     :href="`/preview/admin/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}/files/${a.value}`"
                     target="_blank" class="btn btn-default" title="클릭하여 미리보기">
                    <i class="fa fa-eye"></i>
                    <span>미리보기</span>
                  </a>
                  <a v-if="i.type === 'file'" :href="`/download/admin/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}/files/${a.value}`" target="_blank" rel="noopener noreferrer"
                     class="btn btn-default" title="클릭하여 다운로드">
                    <i class="fa fa-download"></i>
                    <span>다운로드</span>
                  </a>
                  <a class="btn btn-default" @click="reject(a.id)" title="클릭하면 보완 사유 입력 팝업">
                    <i class="fa fa-hand-stop-o"></i>
                    <span>보완</span>
                  </a>
                </div>
              </div>
            </template>
            <span v-else class="empty">(N/A)</span>
          </td>
        </tr>
      </table>
      <div v-if="idx1 === state.application.info.steps.length - 1">
        <b class="title">기타</b>
        <table class="table table-bordered">
          <tr>
            <th>
              <div>증권팀 코멘트</div>
              <div class="desc">코멘트를 수정하면 신청자에게 알림톡이 발송됩니다.</div>
            </th>
            <td>
              <div class="vertical-layout">
                <textarea class="form-control font-sm border-focus-point" v-model="state.application.info.comment"/>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-purple btn-block" type="submit" @click="submit()">저장하기</button>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import http from "@/scripts/http";
import TinyEditor from "@/components/TinyEditor.vue";

function Component(initialize) {
  this.name = "modalArchiveApplicationDetail";
  this.initialize = initialize;
}

export default defineComponent({
  components: {TinyEditor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "lg"});

      load();
    });

    const state = reactive({
      application: {
        loaded: false,
        status: null,
        managers: [],
        assignedManagers: [],
        info: {
          id: 0,
          canceled: false,
          constraintId: 0,
          createDate: "",
          finished: false,
          formId: 0,
          archiveTitle: "",
          keyAnswer: "",
          passportId: 0,
          saveDate: "",
          status: "",
          steps: [],
          updateDate: "",
          comment: "",
          memberName: "",
          memberId: "",
          submitDate: "",
        },
      }
    });

    const modalParams = store.getters.modalParams(component);

    const load = () => {
      state.application.loaded = false;

      for (let i = 0; i < 3; i += 1) {
        state.application.info.steps.push({
          title: "Wait a moment",
          items: [{
            title: "Wait",
            type: "text",
            answers: [{
              value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }]
          }, {
            title: "Wait",
            type: "text",
            answers: [{
              value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }]
          }, {
            title: "Wait",
            type: "text",
            answers: [{
              value: "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            }]
          },]
        });
      }

      http.get(`/api/admin/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}`).then((res1) => {
        state.application.loaded = true;
        state.application.info = res1.data.body;
        state.application.status = res1.data.body.status;
        state.application.assignedManagers = res1.data.body.managers;
      });

      const args = {
        memberAuth: "MEMBER_MID_ADMIN",
        leaveFlag: "N",
        omcTeam: ["invest", "operate"],
      };

      const queryString = new URLSearchParams();

      args.omcTeam.forEach(team => {
        queryString.append("omcTeam", team);
      });

      http.get(`/api/admin/members?${queryString.toString()}`).then((res2) => {
        state.application.managers = res2.data.body.content;
      });
    };

    let reasons = [];

    const submit = () => {
      for (let i = 0; i < state.application.info.steps.length; i++) {
        for (let j = 0; j < state.application.info.steps[i].items.length; j++) {
          for (let k = 0; k < state.application.info.steps[i].items[j].answers.length; k++) {
            reasons.push({answerId: state.application.info.steps[i].items[j].answers[k].id, reason: state.application.info.steps[i].items[j].answers[k].reason});
          }
        }
      }

      const args = {
        status: state.application.status,
        steps: state.application.info.steps,
        reasons: reasons,
        comment: state.application.info.comment,
      };

      store.commit("confirm", {
        message: "신청서를 변경하시겠습니까?",
        allow() {
          const modal = store.getters.modals()[0];

          let uploadPromises = [];

          state.application.info.steps.forEach(step => {
            step.items.forEach(item => {
              const formData = new FormData();
              let hasNewFiles = false;

              item.files?.forEach(fileData => {
                if (fileData.isNew) {
                  formData.append("files", fileData.file);
                  hasNewFiles = true;
                }
              });

              if (hasNewFiles) {
                // 각 itemId에 대한 업로드 요청
                let uploadPromise = http.post(`/api/admin/archives/steps/items/${item.id}/applications/${modalParams.application.id}/uploads`, formData);
                uploadPromises.push(uploadPromise);
              }
            });
          });

          // 모든 업로드가 완료된 후의 처리
          Promise.all(uploadPromises).then(() => {
            http.put(`/api/admin/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}`, args).then(() => {
              store.commit("setSwingMessage", "신청서를 변경하였습니다.");
              store.dispatch("callback", {modal});
              load();
            });
          }).catch(error => {
            console.error("업로드 실패: ", error);
          });
        },
        disallow() {
          state.application.status = modalParams.application.status;
        }
      });
    };

    const edit = () => {
      store.commit("confirm", {
        message: "해당 신청서를 수정하시겠습니까?",
        allowTxt: "수정",
        allow() {
          window.open(`/archives/${modalParams.application.archiveName}/applications/${modalParams.application.id}`, "_blank", "noopener,noreferrer");
        },
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "해당 신청서를 삭제하시겠습니까?",
        allowTxt: "삭제",
        allow() {
          http.delete(`/api/admin/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}`).then(() => {
            store.commit("closeModal", {
              name: component.name,
              onClose(modal) {
                store.dispatch("callback", {modal});
                store.commit("setSwingMessage", "신청서가 삭제되었습니다.");
              }
            });
          });
        },
      });
    };

    const cancel = () => {
      store.commit("confirm", {
        message: "해당 신청서를 취소하시겠습니까?",
        allowTxt: "수정",
        allow() {
          http.put(`/api/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}/cancel`).then(async () => {
            load();
            await store.commit("setSwingMessage", "신청서를 취소했습니다.");
            await store.dispatch("callback", {component});
          });
        },
      });
    };

    const restore = () => {
      store.commit("confirm", {
        message: "해당 신청서를 복원하시겠습니까?",
        allowTxt: "수정",
        allow() {
          http.put(`/api/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}/restore`).then(async () => {
            load();
            await store.commit("setSwingMessage", "신청서를 복원했습니다.");
            await store.dispatch("callback", {component});
          });
        },
      });
    };

    const setReject = ({answerId, reason}) => {
      state.application.status = "denied";

      for (let i = 0; i < state.application.info.steps.length; i++) {
        for (let j = 0; j < state.application.info.steps[i].items.length; j++) {
          for (let k = 0; k < state.application.info.steps[i].items[j].answers.length; k++) {
            if (state.application.info.steps[i].items[j].answers[k].id === answerId) {
              state.application.info.steps[i].items[j].answers[k].reason = reason;
            }
          }
        }
      }
    };

    const reject = (answerId) => {
      store.commit("openModal", {
        name: "ArchiveApplicationItemReject",
        params: {answerId},
        callback: `${component.name}.setReject`
      });
    };

    const removeReject = (answerId) => {
      state.application.info.steps.forEach(step => {
        step.items.forEach(item => {
          item.answers.forEach(answer => {
            if (answer.id === answerId) {
              answer.reason = "";
            }
          });
        });
      });
    };

    const setFile = (e, itemId, applicationId) => {
      if (!store.getters.isAllowedExtension(e.target, "all")) {
        return;
      }

      const newFile = e.target.files[0];
      const fileName = newFile.name;
      const extension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

      state.application.info.steps.forEach((step) => {
        step.items.forEach((item) => {
          if (item.id === itemId) {
            const fileData = {
              file: newFile, // 실제 File 객체
              delFlag: "N",
              itemId: itemId,
              applicationId: applicationId,
              originName: fileName,
              extension: extension,
              isNew: true
            };
            item.files.push(fileData);
          }
        });
      });
    };

    const clearInput = (e) => {
      e.currentTarget.querySelector("input").value = "";
    };

    const removeFile = (fileId, stepIndex, itemIndex) => {
      // 해당 파일을 찾습니다.
      let files = state.application.info.steps[stepIndex].items[itemIndex].files;
      let fileIndex = files.findIndex(f => f.id === fileId);

      if (fileIndex !== -1) {
        files[fileIndex].delFlag = "Y";
      }
    };

    const computedItemFiles = computed(() => {
      let files = [];
      let modalParamApplicationId = modalParams.application.id;

      state.application.info.steps.forEach((step, stepIndex) => {
        step.items.forEach((item, itemIndex) => {
          item.files?.forEach(file => {
            if (file.delFlag !== "Y" && file.itemId === item.id && file.applicationId !== null && file.applicationId === modalParamApplicationId) {
              files.push({...file, stepIndex, itemIndex});
            } else if (file.delFlag !== "Y" && file.itemId === item.id && file.applicationId === null) {
              files.push({...file, stepIndex, itemIndex});
            }
          });
        });
      });

      return files;
    });

    const formatCurrency = (value) => {
      const number = parseFloat(value);
      return !isNaN(number) ? number.toLocaleString() : "(N/A)";
    };

    const toggleManagerSelection = (manager) => {
      const index = state.application.assignedManagers.findIndex(m => m.memberSeq === manager.memberSeq);
      const args = {
        memberSeq: manager.memberSeq,
      };

      if (index === -1) {
        if (state.application.assignedManagers.length < 2) {
          http.put(`/api/admin/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}/manager`, args);
          state.application.assignedManagers.push(manager);
          store.dispatch("callback", {component});
        } else {
          store.commit("setSwingMessage", "담당지는 최대 2명까지 선택할 수 있습니다.");
        }
      } else {
        http.delete(`/api/admin/archives/${modalParams.application.archiveId}/applications/${modalParams.application.id}/manager/${manager.memberSeq}`);
        state.application.assignedManagers.splice(index, 1);
        store.dispatch("callback", {component});
      }
    };

    const isSelected = (memberSeq) => {
      return state.application.assignedManagers.some(m => m.memberSeq === memberSeq);
    };

    const history = () => {
      store.commit("openModal", {
        name: "ArchiveApplicationHistory",
        params: {
          id: state.application.info.id
        },
        callback: `${component.name}.load`
      });
    };

    const getManagerClass = (memberName) => {
      if (memberName === "김정미") {
        return "kim";
      } else if (memberName === "오현경") {
        return "oh";
      } else if (memberName === "안성규") {
        return "ahn";
      } else if (memberName === "성진경") {
        return "sung";
      } else if (memberName === "조일한") {
        return "jo";
      } else if (memberName === "염기석") {
        return "yeom";
      }
    };

    return {
      component,
      state,
      modalParams,
      edit,
      remove,
      reject,
      setReject,
      removeReject,
      submit,
      clearInput,
      computedItemFiles,
      removeFile,
      setFile,
      cancel,
      restore,
      formatCurrency,
      toggleManagerSelection,
      isSelected,
      history,
      getManagerClass,
    };
  }
});
</script>

<style lang="scss" scoped>
.application-detail {
  background-color: #fff;
  min-height: $px300;
  padding: $px20;

  .header {
    .top {
      margin-bottom: $px21;

      > .wrapper {
        display: flex;
        justify-content: space-between;

        > .title {
          display: inline-block;
          font-size: $px18;

          b {
            font-weight: 600;
          }
        }

        .side {
          color: #aaa;

          .text {
            font-size: $px12;
            margin-right: $px10;
          }

          .ico {
            cursor: pointer;
            margin-right: $px5;
          }
        }
      }
    }

    > .actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      .history {
        margin-left: $px5;
      }

      select, .btn {
        font-size: $px12;
        display: inline-block;
        width: initial;
        height: $px43;
      }

      .managers {
        display: inline;
        margin-right: 5px;

        span {
          color: $colorLight;
          font-size: $px13;
          background-color: $colorAnchor;
          padding: $px8 $px12;
          border: $px1 solid $colorAnchor;
          border-radius: $px8;
        }

        .kim {
          background-color: $colorWarning;
          border-color: $colorWarning;
        }

        .oh {
          background-color: #00FFFF;
          border-color: #00FFFF;
        }

        .ahn {
          background-color: #90EE90;
          border-color: #90EE90;
        }

        .sung {
          background-color: #FF6961;
          border-color: #FF6961;
        }

        .jo {
          background-color: #9370DB;
          border-color: #9370DB;
        }

        .yeom {
          background-color: #2471ED;
          border-color: #2471ED;
        }
      }
    }

    .dropdown {
      position: relative;

      .btn {
        > div {
          display: inline-block;

          .condition {
            color: $colorPurple;
          }

          &:not(:last-child) {
            padding-right: $px4;
          }
        }

        &:after {
          margin-left: $px7;
        }
      }

      .dropdown-menu {
        min-width: $px120;
        position: absolute;
        box-shadow: none;
        border: $px1 solid $colorBorder;
        margin-top: $px3;
        z-index: 10;

        > div {
          white-space: break-spaces;

          .title {
            font-size: $px12;
            font-weight: 500;
            display: block;
            white-space: nowrap;
            margin-bottom: $px3;
          }

          .dropdown-item {
            cursor: pointer;
            display: inline-block;
            font-size: $px12;
            padding: $px10;

            > span {
              white-space: nowrap;
            }

            i {
              visibility: hidden;

              &.active {
                visibility: visible;
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: $px5;
          }
        }
      }
    }
  }

  .alert {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    font-size: $px13;
  }

  .content {
    margin-top: $px20;

    a > span {
      color: $colorTextDefault;
    }

    .btn {
      margin-top: $px10;
      font-size: $px10;
      padding: $px6 $px12;
    }

    > .title {
      display: inline-block;
      margin-bottom: $px10;
    }

    table {
      table-layout: fixed;
      margin-bottom: $px40;

      tr {
        th, td {
          padding: $px12 $px15;
        }

        th {
          background: #f7f7f7;
          width: $px170;
          max-width: $px170;

          > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.desc {
              font-size: $px10;
              white-space: pre-line;
              color: #aaa;
              font-weight: 400;
            }

            .remove {
              cursor: pointer;
              margin-right: $px5;
            }

            .blue {
              color: $colorAnchor;
            }

            .green {
              color: $colorSuccess;
            }
          }
        }

        td {
          .empty {
            color: #ccc;
          }

          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $px1;

            .vertical-layout {
              display: flex;
              align-items: flex-start;
              flex-direction: column;

              .reason {
                color: $colorDanger;
              }

              .remove {
                margin-right: $px5;
              }

              .editor {
                width: $px200;
              }

              .form-control {
                height: $formHeight;
              }
            }

            span {
              white-space: pre-line;

              &.file-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: $px400;

                &:hover {
                  text-decoration: underline;
                }
              }
            }

            .actions {
              a > span {
                color: $colorTextDefault;
              }

              .btn {
                font-size: $px10;
                padding: $px6 $px12;

                i {
                  display: inline-block;
                  margin-right: $px4;
                }

                span {
                  white-space: nowrap;
                }
              }
            }

            &.file {
              &:not(:last-child) {
                margin-bottom: $px8;
              }
            }
          }

          &:not(:last-of-type) {
            margin-bottom: $px10;
          }
        }

        &:first-child {
          th, td {
            border-top: none;
          }
        }
      }
    }
  }

  &.skeleton {
    .header {
      .top .title, span {
        @include skeleton;
      }

      .actions {
        select, .btn {
          @include skeleton;
        }
      }
    }

    .content {
      b {
        @include skeleton;
      }

      .table > tr {
        th, td {
          div, .btn, span {
            @include skeleton;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .content table tr th {
      width: $px100;
      max-width: $px100;
    }

    .content table tr th div span &.file-name {
      max-width: $px200;
    }
  }

  @media(max-width: 767px) {
    .header {
      .top {
        flex-wrap: wrap;
      }
    }
  }
}
</style>